
// Dependencies
import { createApp } from 'vue';
import VueGtag from "vue-gtag";

// Local
import App from './App.vue';

// Config
const GTAG_PARAMS = { config: { id: "G-GNSHHDJJ7F" } };

// Init and mount
createApp(App)
	.use(VueGtag, GTAG_PARAMS)
	.mount('#app');
